<template>
  <v-container fluid class="pt-0 fill-height d-flex flex-column justify-start" v-if="building">
    <MainModal
        :main="{ component: 'BuildingIndicator', title: 'Якісні показники' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_building_indicator)"
        :modal="show_building_indicator_dialog"
        @updateItemModal="buildingIndicatorUpdateItemModal"
    />
    <MainModal
        :main="{ component: 'BankAccount', title: 'Банківські рахунки' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_bank_account)"
        :modal="show_bank_account_dialog"
        @updateItemModal="bankAccountUpdateItemModal"
    />
    <MainModal
        :main="{ component: 'Flat', title: 'Квартири' }"
        :button="{ icon: false, buttonIcon: 'mdi-plus', buttonHidden: true }"
        :item="Object.assign({}, selected_flat_create)"
        :modal="show_flat_create_dialog"
        @updateItemModal="flatCreateUpdateItemModal"
    />
    <v-row justify="center" style="flex: 0; width: 100%">
      <v-toolbar elevation="1" height="68" style="padding-top: 6px">
        <v-toolbar-title>
          {{ building.full_address }}
        </v-toolbar-title>
        <v-spacer/>
        <v-menu offset-y v-model="menu">
          <template v-slot:activator="{ on, attrs }">
            <v-btn
                class="grey lighten-5"
                icon
                outlined
                v-bind="attrs"
                v-on="on"
                @click="menu = true"
            >
              <v-icon>mdi-arrow-down-drop-circle-outline</v-icon>
            </v-btn>
          </template>
          <v-list dense rounded nav>
            <v-list-item link class="px-4" @click.stop="deleteDialog">
              <v-list-item-icon>
                <v-icon color="error lighten-1">mdi-delete-forever-outline</v-icon>
              </v-list-item-icon>
              <v-list-item-title class="font-weight-medium">Вилучити</v-list-item-title>
            </v-list-item>
          </v-list>
        </v-menu>
      </v-toolbar>
    </v-row>
    <v-row style="flex: 1; width: 100%">
      <v-col cols="12" md="12" class="px-0">
        <v-card-text class="pa-0 fill-height">
          <v-tabs class="custom-tabs fill-height" color="success">
            <v-tab class="text-left justify-start">
              Дані будинку
            </v-tab>
            <v-tab class="text-left justify-start">
              Особові рахунки
            </v-tab>

            <v-tab class="text-left justify-start">
              Банківські рахунки
            </v-tab>

            <v-tab-item class="pa-0">
              <v-row>
                <v-col cols="12" md="8">
                  <v-card tile elevation="1" class="fill-height" style="border-left: 3px solid #5bb55f"
                          min-height="280">
                    <v-card-text>
                      <v-row>
                        <v-col cols="12">
                          <v-text-field type="text" hide-details filled label="Адреса будинку" v-model="address"
                                        disabled></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <AddressElementSelect v-model="city"
                                                label="Населений пункт" filled
                                                select_type="city"
                                                :disabled="!edit"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <AddressElementSelect :parent_id="city" v-model="street"
                                                label="Вулиця(-ки)" filled
                                                select_type="street" use_parent_id
                                                :disabled="!city  || !edit"
                          />
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field type="text" hide-details filled label="№ будинку"
                                        v-model="buildNumber" :disabled="!edit"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-select :items="BuildingTypes" hide-details filled label="Вид будинку"
                                    v-model="buildType" :disabled="!edit"></v-select>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field type="text" hide-details filled label="Початковий особовий"
                                        v-model="personIdStart" :disabled="!edit"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field type="text" hide-details filled label="Кінцевий особовий"
                                        v-model="personIdEnd" :disabled="!edit"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field type="text" hide-details filled label="Порядковий номер будинку"
                                        v-model="sequenceNumber" :disabled="!edit"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6">
                          <v-text-field type="text" hide-details filled label="Клас будинку"
                                        v-model="buildClass" :disabled="!edit"></v-text-field>
                        </v-col>
                        <v-col cols="12" md="6" @click="buildingSaveOrEdit">
                          <v-btn depressed block :color="edit ? 'success' : 'secondary'" height="56">
                            <v-icon left>
                              {{ edit ? 'mdi-content-save-outline' : 'mdi-circle-edit-outline' }}
                            </v-icon>
                            {{ edit ? 'Зберегти' : 'Редагувати' }}
                          </v-btn>
                        </v-col>
                      </v-row>
                    </v-card-text>
                  </v-card>
                </v-col>
                <v-col cols="12" md="4">
                  <v-card tile elevation="1" class="fill-height" style="border-left: 3px solid #4caf50">
                    <v-card-title class="pa-0">
                      <v-toolbar elevation="0" class="pa-0">
                        <v-toolbar-title>
                          Якісні показники
                        </v-toolbar-title>
                        <v-spacer></v-spacer>
                        <v-tooltip bottom color="success">
                          <template v-slot:activator="{ on, attrs }">
                            <v-btn icon @click="openBuildingIndicatorCreateDialog"
                                   v-bind="attrs"
                                   v-on="on"
                                   class="grey lighten-4 mr-1">
                              <v-icon>mdi-plus</v-icon>
                            </v-btn>
                          </template>
                          <span>Створити новий якісний показник</span>
                        </v-tooltip>
                      </v-toolbar>
                    </v-card-title>
                    <v-list class="pa-0">
                      <v-divider/>
                      <template v-for="(item, index) in indicators">
                        <v-list-item :key="`itm-${index}`" style="min-height: 36px" @click="onBuildingIndicatorItemClick(item)">
                          <v-list-item-avatar size="26">
                            <v-icon size="18"
                                    color="white"
                                    class="success"
                            >
                              mdi-lightbulb-group-outline
                            </v-icon>
                          </v-list-item-avatar>
                          <v-list-item-content class="subtitle-1 py-1">
                            <v-list-item-title>
                              {{ item.indicator.name }}
                            </v-list-item-title>
                            <v-list-item-title class="font-weight-medium">
                              {{ item.value_dec | formatNumber }}
                            </v-list-item-title>
                          </v-list-item-content>
                          <v-list-item-action>
                            <v-list-item-action-text>
                              {{ item.date_start | formatDate }}
                            </v-list-item-action-text>
                            <v-list-item-action-text>
                              {{ item.date_end !== null ? `по ${item.date_end}` : '' }}
                            </v-list-item-action-text>
                          </v-list-item-action>
                        </v-list-item>
                        <v-divider :key="`vdv-${index}`"></v-divider>
                      </template>
                    </v-list>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item class="pa-0">
              <v-row>
                <v-col cols="12">
                  <v-card class="fill-height" tile elevation="1" style="border-left: 3px solid #5bb55f">
                    <v-card-title>
                      <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Пошук"
                          placeholder="Почніть вводити текст для пошуку"
                          single-line
                          hide-details
                          class="px-4 mb-3"
                          color="grey"
                      />
                      <v-tooltip bottom color="success">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon @click="openFlatCreateDialog"
                                 v-bind="attrs"
                                 v-on="on"
                                 class="grey lighten-4 mr-1">
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>Створити нового абонента</span>
                      </v-tooltip>
                    </v-card-title>
                    <v-card-text>
                      <v-data-table
                          :headers="headers"
                          :items="accounts"
                          :search="search"
                          :items-per-page="10"
                          :footer-props="{
                                          showFirstLastPage: true,
                                          itemsPerPageText: 'Рядків на сторінку',
                                          itemsPerPageOptions: [10, 15, 30, -1],

                                        }"
                          @click:row="openWorkPlace"
                          class="custom-table"
                      >
                        <template v-slot:item.personal_id="{ item }">
                          <v-icon class="mr-2" color="success" size="22">mdi-file-document-outline</v-icon>
                          <span>{{ item.personal_id }}</span>
                        </template>
                        <template v-slot:item.end_balance="{ item }">
                          <span
                              :class="item.end_balance <= 0 ? 'success--text' : 'error--text text--lighten-1'"
                              style="font-weight: bold"
                          >
                            {{ item.end_balance }}
                          </span>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>

            <v-tab-item class="pa-0">
              <v-row>
                <v-col cols="12">
                  <v-card class="fill-height" tile elevation="1" style="border-left: 3px solid #5bb55f">
                    <v-card-title>
                      <v-text-field
                          v-model="search"
                          append-icon="mdi-magnify"
                          label="Пошук"
                          placeholder="Почніть вводити текст для пошуку"
                          single-line
                          hide-details
                          class="px-4 mb-3"
                          color="grey"
                      />
                      <v-tooltip bottom color="success">
                        <template v-slot:activator="{ on, attrs }">
                          <v-btn icon @click="openBankAccountCreateDialog"
                                 v-bind="attrs"
                                 v-on="on"
                                 class="grey lighten-4 mr-1">
                            <v-icon>mdi-plus</v-icon>
                          </v-btn>
                        </template>
                        <span>Створити новий банківський рахунок</span>
                      </v-tooltip>
                    </v-card-title>
                    <v-card-text>
                      <v-data-table
                          :headers="bankAccountsHeaders"
                          :items="bankAccounts"
                          :search="search"
                          :items-per-page="10"
                          :footer-props="{
                                          showFirstLastPage: true,
                                          itemsPerPageText: 'Рядків на сторінку',
                                          itemsPerPageOptions: [10, 15, 30, -1],

                                        }"
                          @click:row="onBankAccountItemClick"
                          class="custom-table"
                      >
                        <template v-slot:item.icon>
                          <v-icon size="26" class="mr-2" color="success">
                            mdi-bank
                          </v-icon>
                        </template>
                        <template v-slot:item.main="{ item }">
                          <v-simple-checkbox color="grey" :value="item.main === null ? false : item.main"
                                             :disabled="true"/>
                        </template>
                        <template v-slot:item.account_type="{ item }">
                          {{ bankAccountTypes[item.account_type] || 'Розрахунковий' }}
                        </template>
                        <template v-slot:item.disable="{ item }">
                          <v-simple-checkbox color="grey" :value="item.disable === null ? false : item.disable"
                                             :disabled="true"/>
                        </template>
                        <template v-slot:item.service_name="{ item }">
                          <span>{{ item.service_name }}</span>
                        </template>
                      </v-data-table>
                    </v-card-text>
                  </v-card>
                </v-col>
              </v-row>
            </v-tab-item>
          </v-tabs>
        </v-card-text>
      </v-col>
    </v-row>
  </v-container>
</template>

<script>
import bankAPI from "@/utils/axios/bank"
import {accountIcons, buildingTypes_select} from "@/utils/icons";
import {mapGetters, mapActions} from 'vuex'
import {DELETE_BUILDING, FLAT_BY_BUILDING, GET_BUILDING, UPDATE_BUILDING} from "@/store/actions/building";
import {FETCH_BUILDING_INDICATORS, FETCH_BUILDING_INDICATOR_TYPES} from "@/store/actions/building_indicators";
import {QUESTION_SHOW} from "@/store/actions/question";
import {bankAccountTypes} from '@/utils/icons'

const modalDeleteId = 'building_modal_delete'

export default {
  name: "BuildingView",
  components: {
    MainModal: () => import("@/components/modal/MainModal"),
    AddressElementSelect: () => import('@/components/autocomplite/AddressElementSelect')
  },
  data() {
    return {
      menu: false,
      delete_watcher: null,
      edit: false,
      address: '',
      city: null,
      street: null,
      buildNumber: null,
      buildClass: null,
      sequenceNumber: null,
      personIdStart: null,
      personIdEnd: null,
      buildType: null,
      cities: [],
      streets: [],
      BuildingTypes: buildingTypes_select,
      buildingParams: [
      ],
      headers: [
        {text: 'Особовий рахунок', value: 'person_id', width: 190},
        {text: '№ кв.', value: 'flat_number', align: 'center'},
        {text: 'Власник', value: 'owner_name'},
        {text: 'Телефон', value: 'phone'},
        {text: 'Площа', value: 'square', align: 'right'},
        {text: 'Сальдо', value: 'end_balance', align: 'right'},
      ],
      bankAccountsHeaders: [
        {text: '', value: 'icon', width: 26},
        {text: 'Основ.', value: 'main', width: 100},
        {text: 'Відкл.', value: 'disable', width: 100},
        {text: 'Вид рахунка', value: 'account_type'},
        {text: 'Банк', value: 'bank_name'},
        {text: 'МФО', value: 'bank_mfo'},
        {text: '№ рахунка', value: 'account'},
        {text: 'Послуга', value: 'service_name'}
      ],
      search: '',
      bankAccountTypes,
      bankAccounts: [],

      selected_building_indicator: {},
      show_building_indicator_dialog: false,

      selected_bank_account: {},
      show_bank_account_dialog: false,

      selected_flat_create: {},
      show_flat_create_dialog: false
    }
  },
  methods: {
    ...mapActions({
      fetchOne: GET_BUILDING,
      fetchIndicators: FETCH_BUILDING_INDICATORS,
      fetchIndicatorTypes: FETCH_BUILDING_INDICATOR_TYPES,
      saveBuilding: UPDATE_BUILDING,
      fetchFlatByBuilding: FLAT_BY_BUILDING,
    }),
    fetchBankAccounts() {
      bankAPI.get_bank_accounts_by_building(this.$route.params.id)
          .then(response => response.data)
          .then(data => this.bankAccounts = data)
    },
    deleteDialog() {
      this.menu = false
      const payload = {
        text: `Підтвердіть вилучення будинку за адресою ${this.building.full_address}`,
        accept_button: true,
        id: modalDeleteId
      }
      this.$store.dispatch(QUESTION_SHOW, payload)
    },
    openWorkPlace(item) {
      this.$router.push(
          {name: 'WorkPlace', params: { person_hash: item.person_hash }}
      )
    },
    getIcon(name, type) {
      const defaultIcon = {icon: 'mdi-text-box-check-outline', color: 'success'}
      const value = accountIcons[name] || defaultIcon
      return value[type]
    },
    buildingSaveOrEdit() {
      if (this.edit) {
        const payload = {
          city_id: this.city,
          street_id: this.street,
          build_number: this.buildNumber,
          build_class: this.buildClass,
          sequence_number: this.sequenceNumber,
          person_id_start: this.personIdStart,
          person_id_end: this.personIdEnd,
          building_type: this.buildType,
          id: this.building.id
        }
        this.saveBuilding(payload).then((e) => {
          if (e) {
            this.edit = false
          }
        })
      } else {
        this.edit = true
      }
    },
    watch_modal_answer() {
      this.delete_watcher = this.$watch(
          'modalAnswer',
          {
            handler(payload) {
              if (payload.id === modalDeleteId) {
                if (payload.answer) {
                  this.$store.dispatch(DELETE_BUILDING, this.$route.params.id)
                      .then(ok => {
                        if (ok) {
                          this.$router.push({name: 'Streets', query: { current: 2 } })
                        }
                      })
                }
              }
            }
          }
      )
    },
    flatCreateUpdateItemModal() {
      this.show_flat_create_dialog = false
      this.selected_flat_create = {}
    },
    openFlatCreateDialog() {
      this.selected_flat_create = JSON.parse(JSON.stringify({
        city_id: this.building.city_id,
        street_id: this.building.street_id,
        building_id: this.building.id,
        create_from_building: true
      }))
      this.show_flat_create_dialog = true
    },

    buildingIndicatorUpdateItemModal() {
      this.show_building_indicator_dialog = false
      this.selected_building_indicator = {}
    },
    openBuildingIndicatorCreateDialog() {
      this.selected_building_indicator = {building_name: this.building.full_address, building_id: this.building.id}
      this.show_building_indicator_dialog = true
    },
    onBuildingIndicatorItemClick(payload) {
      const local_payload = JSON.parse(JSON.stringify(payload))
      local_payload.building_name = this.building.full_address
      local_payload.indicator_id = (local_payload.indicator || {}).id
      local_payload.name = (local_payload.indicator || {}).name

      this.selected_building_indicator = local_payload
      this.show_building_indicator_dialog = true
    },

    bankAccountUpdateItemModal() {
      this.show_bank_account_dialog = false
      this.selected_bank_account = {}

      this.fetchBankAccounts()
    },
    openBankAccountCreateDialog() {
      this.selected_bank_account = {
        is_organization_service: true,
        is_service_service: true,
        is_building_service: true,
        building_id: this.building.id
      }
      this.show_bank_account_dialog = true
    },
    onBankAccountItemClick(payload) {
      const local_payload = JSON.parse(JSON.stringify(payload))
      local_payload.building_id = local_payload.building_id || this.building.id
      local_payload.is_organization_service = true
      local_payload.is_service_service = true
      local_payload.is_building_service = true

      this.selected_bank_account = local_payload
      this.show_bank_account_dialog = true
    },
  },
  computed: {
    ...mapGetters({
      building: 'getBuilding',
      indicators: 'getBuildingIndicators',
      accounts: 'getFlatsByBuilding',
      modalAnswer: 'question_answer',
    }),
  },
  created() {
    this.watch_modal_answer()

    this.fetchOne(this.$route.params.id)
      .then(response => {
        if (response) {
          this.address = this.building.address
          this.city = this.building.city_id
          this.street = this.building.street_id
          this.buildNumber = this.building.build_number
          this.personIdStart = this.building.person_id_start
          this.personIdEnd = this.building.person_id_end
          this.buildClass = this.building.build_class
          this.buildType = this.building.building_type
          this.sequenceNumber = this.building.sequence_number

          this.fetchIndicators(this.$route.params.id)
          this.fetchIndicatorTypes()

          this.fetchFlatByBuilding(this.$route.params.id)
          this.fetchBankAccounts()
        }
      })
  },
  beforeDestroy() {
    if (this.delete_watcher) {
      this.delete_watcher()
    }
  }
}
</script>

<style scoped lang="scss">
.custom-tabs {
  :deep(.v-window.v-item-group.theme--light) {
    background-color: transparent;
  }

  :deep(div[role="tablist"]) {
    background-color: #f5f5f5 !important;
  }
  :deep(.v-window__container) {
    height: 100%;
  }
  :deep(.v-window-item) {
    height: 100%;
  }
  :deep(.v-window-item > .row) {
    height: 100%;
  }
}
</style>